import React from 'react';
import ComingSoon from './ComingSoon';

const Donate = () => {
    return (
        <div>
            <ComingSoon/>        
        </div>
    );
};

export default Donate;